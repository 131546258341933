import $ from 'jquery';
import React from 'react';
import {getWaUrlPath,CallMapMethod_CBack,_getInitParam} from '../function_lib/common_lib';



export const Comp_Invoice_Products=(props)=>{
  let _initSettings=_getInitParam();
  _initSettings=_initSettings.app_settings.settings;

  let shipmentData={};
  



 
  let listDispatch=[];
  //alert(JSON.stringify(props.mainInvoice));
  if(props.mainInvoice.length>0){
    if(props.mainInvoice[0].track_name!=="" && props.mainInvoice[0].track_name!=="0"){
      shipmentData=JSON.parse(props.mainInvoice[0].track_name);
    }
    if(props.mainInvoice[0].track_url.trim()!=="")
    listDispatch= JSON.parse(props.mainInvoice[0].track_url);
   
  }
    let _imgPath=getWaUrlPath();

    let _netWeight=0;
    let _productAmount=0;
return (<div className="modal fade bd-example-modal-xl"  id="divInvoiceProducts" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog modal-xl" role="document">
  <div className="modal-content">
  <div className="modal-header bg-primary">
      <h5 className="modal-title text-white" id="exampleModalLabel">Order List</h5>
      <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" className="text-white">&times;</span>
      </button>
    </div>
{/* invoice details */}
    <div className="modal-body">
    <div className="card card-info">
  <div className="card-header">
    <h3 className="card-title">Invoice/Order Information</h3>
  </div>
  {props.mainInvoice.length>0?<div className="card-body">
    <table className="dtgrid table" style={{"over-flow-x":"scroll"}}>
      <tr>
        <td>Order No:<b>{props.mainInvoice[0]["invoice_no"]}</b></td>
        <td>Order Date :<b>{props.mainInvoice[0]["month_date"]}</b> </td>
        <td>Customer Name :<b>{props.mainInvoice[0]["first_name"]+" "+props.mainInvoice[0]["last_name"]}</b></td>
        <td>Email/Contact No :<b>{props.mainInvoice[0]["email_id"]+"/"+props.mainInvoice[0]["contact_no"]}</b></td>
        
        <td>Order Status :<b>{props.mainInvoice[0]["order_status"]}</b> </td>
      </tr>
      {props.inv_courier.length>0?
      <tr>
        <td>Payment Status :<b>{props.mainInvoice[0]["payment_status"]}</b> </td>
        <td>Purchase Courier:<b>{props.inv_courier[0]["description"]}</b></td>
        <td>Paid Courier Charges:<b>{_initSettings.currency+props.inv_courier[0]["break_down_amount"]}</b></td>
        <td>Shipment ID:<b>{typeof shipmentData.shipment_id!=="undefined"?shipmentData.shipment_id:''}</b></td>  
        {typeof shipmentData.shipment_id==="undefined"?<td>  
          <div className="input-group input-group-sm">
          <label>*applicable to shiprocket</label>
          <button type="button" onClick={()=>props.pushAutoShipment(props.app_user_id,props.invoiceProducts.length>0?btoa(props.invoiceProducts[0]["invoice_id"]):btoa("0"))} class="btn btn-danger mt-2">Generate Shipment</button>
          </div> </td>:''}
      </tr>
      :''}
    </table>
    </div>:''}
    {props.billingAddress.length>0?
      <table className="dtgrid table">
      <tr>
        <td>
          <b>Billing Address</b><br></br>
          {"Name:"+props.billingAddress[0]["name"]}<br></br>
          {"Contact No:"+props.billingAddress[0]["mobile_no"]}<br></br>
          {"Address:"+props.billingAddress[0]["address"]}<br></br>
          {"City:"+props.billingAddress[0]["city"]+",Pincode:"+props.billingAddress[0]["pincode"]}<br></br>
          {"State:"+props.billingAddress[0]["state_nm"]}<br></br>
        </td>
        <td>
        <b>Shipping Address</b><br></br>
        {"Name:"+props.shippingAddress[0]["name"]}<br></br>
        {"Contact No:"+props.shippingAddress[0]["mobile_no"]}<br></br>
          {"Address:"+props.shippingAddress[0]["address"]}<br></br>
          {"City:"+props.shippingAddress[0]["city"]+",Pincode:"+props.shippingAddress[0]["pincode"]}<br></br>
          {"State:"+props.shippingAddress[0]["state_nm"]}<br></br>
        </td>
      </tr>
    </table>
    
    :''
    }
  
    </div>
{/* end of invoice details */}
                    <div class="card-body">
                    <form class="form-group" id="frmInvoiceProducts_nn" name="frmInvoiceProducts_nn" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                    
                        <div className="row">
                          <div className="col-md-4">
                        <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Order Status</label>
                                            <div className="input-group input-group-sm">
                                                <select className="form-control" id="cmdOrderStatus" name="cmdOrderStatus">
                                                  <option value={"0"}>-Select-</option>
                                                  <option value={"Under Process"}>Under Process</option>
                                                  <option value={"Package"}>Package</option>
                                                  <option value={"Dispatched"}>Dispatched</option>
                                                  <option value={"Delivered"}>Delivered</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                        <label for="mail_noti"></label>
                                            <div className="input-group input-group-sm">
                                              <input className="mr-2" defaultChecked={"true"}  type="checkbox" id="mail_noti" name="mail_noti" />
                                              <label for="mail_noti">Update with Mail Notification</label>
                                            </div>
                                        </div>
                                    </div>
                                    
                        <div class="col-lg-6 col-md-6">
                        <div className="col-md-12 respSSL"></div>
                        <div id="testing2dd2222"></div>
                              <div class="form-group">
                              <center>
                                  <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                      <button type="button" onClick={()=>props.updateOrderStatus(props.app_user_id,props.invoiceProducts.length>0?btoa(props.invoiceProducts[0]["invoice_id"]):btoa("0"))} class="btn btn-primary mt-2">Update Order</button>
                                  </div>
                              </center>
                              </div>
                              
                          </div>
</div>
                        
<div className="col-md-8">
{/* dispatch inputs */}
<div className="card card-info">
  <div className="card-header">
    <h3 className="card-title">Courier/Dispatch Update</h3>
  </div>
  <div className="card-body">
    <div className="row">
      <div className="col-lg-4 col-md-4">
      <div className="form-group">
                                            <label for="exampleInputEmail1">Courier Vendor</label>
                                            <div className="input-group input-group-sm">
                                                <select className="form-control" id="cmbCourier" name="cmbCourier">
                                                  <option value={"0"}>-Select-</option>
                                                  {props.courierList.map((item,index)=>{
                                                    return <option value={item.id}>{item.title}</option>
                                                  })}
                                                </select>
                                                
                                                
                                            </div>
                                        </div>

      </div>

      <div className="col-lg-4 col-md-4">
      <div className="form-group">
                                            <label for="exampleInputEmail1">Tracking ID</label>
                                            <div className="input-group input-group-sm">
                                                <input className="form-control" type="text" id="txtUpdOrderNo" name="txtUpdOrderNo" />
                                            </div>
                                        </div>

      </div>
      <div class="col-lg-4 col-md-4">
                        <div className="col-md-6 respSSL"></div>
                        <div id="testing2dd2222"></div>
                        {/* <div className="input-group input-group-sm">
                            <input className="mr-2" defaultChecked={"false"}  type="checkbox" id="auto_push_cor" name="auto_push_cor" />
                            <label for="auto_push_cor">Auto Submit to Vendor</label>
                        </div> */}
                              <div class="form-group input-group-sm">
                                  <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                      <button type="button" onClick={()=>props.updateCourier(props.app_user_id,props.invoiceProducts.length>0?btoa(props.invoiceProducts[0]["invoice_id"]):btoa("0"))} class="btn btn-danger mt-2">Update Logistics</button>
                                  </div>
                              </div>
                              
                          </div>


    </div>

  </div>
  <div className="row">
    <div className="col-md-12">
      <table className="dtgrid table table-bordered table-hover">
        <thead>
          <tr>
            <td>Tracking URL</td>
            <td>Courier Name</td>
            <td>Tracking No</td>
            <td>Action</td>
          </tr>
        </thead>
        {listDispatch.map((item,index)=>{
          return <tr>
            <td><a target="_blank" href={item["url"]}>{item["url"]}</a></td>
            <td>{item["title"]}</td>
            <td>{item["order_no"]}</td>
            <td><a href={"javascript:void(0)"} className="_setbackparam" param1={item["order_no"]} param2={btoa(props.invoiceProducts[0]["invoice_id"])} resback="removeOrder"><i className={"fa fa-times"}></i></a></td>
          </tr>
        })}
        </table>

    </div>
  </div>
</div>  
{/* end of courier */}
</div>

</div>
                        </form>
                        <div class="row">
                        <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Product Code</th>
                      <th>Vendor</th>
                      <th>Qty</th>
                      <th>Qty X Weight</th>
                      <th>Rate</th>
                      <th>Disc.</th>
                      <th>Net Amount</th>
                      {/* <th>Product Status</th> */}
                      {/* <th>Transaction Date</th> */}
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  {
                  
                  props.invoiceProducts.map((item,index)=>{
                    let netWeight=parseFloat(item.product_weight)*parseFloat(item.quantity);
                    _netWeight+=netWeight;
                    _productAmount+=parseFloat(item.final_amount);
                    return <tr>
                        <td>{index+1}</td>
                        <td><img height="50px" width="50px" src={_imgPath+item.img_path} /></td>
                        <td>{item.product_name}</td>
                        <td>{item.product_code}</td>
                        <td>{item.vendor_company}</td>
                        <td>{item.quantity}</td>
                        <td>{netWeight}</td>
                        <td>{item.rate}</td>
                        <td>{item.discount}</td>
                        <td>{item.final_amount}</td>
                        {/* <td>status</td> */}
                        {/* <td>{""}</td> */}
                        {/* <td>action</td> */}
                    </tr>
                  })}
                  <tfoot>
                    <tr>
                      <td colspan="6"></td>
                      <td><b>{_netWeight} gm</b></td>
                      <td></td>
                      <td></td>
                      <td><b>Rs. {_productAmount}/-</b></td>
                      </tr>
                  </tfoot>
                  </table>
                           
                        </div>
                         
                        
                        
                        </div>
                   
  </div>
  </div>
  </div>
  </div>);
}
export default Comp_Invoice_Products;