import $, { extend } from 'jquery';
import React from 'react';


export const CMS_Edit_Header=(props)=>{
    
    let listContentType=[{"home_banner":"Home Banner"},{"home_banner2div":"Home Banner2Div"},{"slider_4":"Slider 4"},
    {"testimonials":"testimonials"},{"single_img_text":"Single Image+Text 3:1"},
    {"collapse_pan":"Collapse Pan List"},{"text_description":"Text Description"},
    {"dual_text_card":"Dual Cards"},{"top_banner":"Top Banner"},
    {"img_text_desc_img_right":"Image Text Desc:Right"},{"img_text_desc_img_left":"Image Text Desc:Left"},
    {"text_image_full":"Text Image Full"},{"left_img_txt_slider":"Left Img Txt Slider"},
    {"right_img_txt_slider":"Right Img Txt Slider"},{"image_collarge_grid":"Image Collarge Grid"},
    {"image_grid":"Image Grid"},{"notice_board":"Notice Board"},{"latest_news":"Latest News"},
    {"image_gallery":"Image Gallery"},{"video_gallery":"Video Gallery"},
    {"video_slider":"Video Slider"},{"list":"List"},{"list_slider":"List Slider"},
    {"product_list":"Product List"},{"product_slider":"Product Slider"},
    {"blogs":"Blogs"},{"blog_listing":"Blog Listing"},{"job_listing":"Job Listing"},
    {"dual_banner_bottom":"Dual Banner Bottom"},{"feature_web_list":"Feature Web List"},
    {"article_listing":"Article Listing"},{"top_info_slider":"Top Info Slider"},
    {"icon_title":"Icon Title"},{"footer_options":"Footer Options"},{"article_listing_arrow":"Article Listing Arrow"},
    {"text_thumbnail":"Text Thumbnail"},{"left_img_two_div_text":"Left Image 2 Div Text Desc"},
    {"right_img_two_div_text":"Right Image 2 Div Text Desc"},{"feature_explore":"Feature Explore"}
];

if(props.editHeaderData.length>0){
    /*
    alert(JSON.stringify(props.editHeaderData));
    alert(props.editHeaderData[0]["col1"]);
    */
   //$("#editcol1").val(props.editHeaderData[0]["col1"]);
   /*
   if(props.editHeaderData[0]["col6"].trim()==="menu"){
    alert("i m in menu");
    $("#chkede_col6").prop("checked", true);
   }
   */
}




    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divEditHeader" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Edit Headers</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            {props.editHeaderData.length>0?
            <form id="frmEditHeader" name="frmEditHeader">
           
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                  <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                                  <input type="hidden" name="curl_type" id="curl_type" value="editCMS_Content_Pg" />
                                  <input type="hidden" name="m_type" id="m_type" value="editCMS_Content_Pg" />
                                  
                                  <input type="hidden" name="edit_id" id="edit_id" value={props.editHeaderData[0].id} />
                                  <input type="hidden" name="local_token" id="local_token" value={props.user_object[0].app_user_id} />
                                  <input type="hidden" name="type" id="type" value={"9"}/>
                                  <div className="card-body">
                                      <div className="row">
                                     
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Select Menu</label>
                                                  <select id="parent_id" name="parent_id" className="form-control">
                                                    <option value={"0"}>-select Menu-</option>
                                                    {props.menus.map((item,index)=>{
                                                        return <option selected={item["id"]==props.editHeaderData[0]["parent_id"] } value={item["id"]}>{item["col1"]}</option>
                                                    })}
                                                  </select>
                                              </div>
                                          </div>
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Title</label>
                                                  <input type="text" id="editcol1" name="col1" defaultValue={props.editHeaderData[0]["col1"]}  className="form-control required" placeholder="Title" />
                                              </div>
                                          </div>
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Url Path</label>
                                                  <input type="text" id="col2" name="col2" defaultValue={props.editHeaderData[0]["col2"]}  className="form-control required12" placeholder="Url Path" />
                                              </div>
                                          </div>
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Meta Title</label>
                                                  <input type="text" id="col3" name="col3" defaultValue={props.editHeaderData[0]["col3"]} className="form-control required1" placeholder="Meta Title" />
                                              </div>
                                          </div>
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Meta Keywords</label>
                                                  <input type="text" id="col4" name="col4" defaultValue={props.editHeaderData[0]["col4"]}  className="form-control required1" placeholder="Meta Keywords" />
                                              </div>
                                          </div>
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Meta Description</label>
                                                  <input type="text" id="col5" name="col5" defaultValue={props.editHeaderData[0]["col5"]}  className="form-control required1" placeholder="Meta Description" />
                                              </div>
                                          </div>
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Content Type</label>
                                                  <select id="col7" name="col7" className="form-control">
                                                    <option value={"0"}>-Select-</option>
                                                   {listContentType.map((item,index)=>{
                                                    let sKey=Object.keys(item);
                                                    return <option selected={sKey==props.editHeaderData[0]["col7"]} value={sKey}>{item[sKey]}</option>
                                                   })}
                                                  </select>
                                              </div>
                                          </div>
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Display Index</label>
                                                  <input type="text" id="col8" name="col8" defaultValue={props.editHeaderData[0]["col8"]} className="form-control required" placeholder="Display Index" />
                                              </div>
                                          </div>
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                              <label for="chkede_col6">if Menu</label>
                                              <select id="col6" name="col6" className="form-control">
                                                <option value={""}>-Data-</option>
                                                <option selected={"menu"==props.editHeaderData[0]["col6"]} value={"menu"}>Menu</option>
                                              </select>
                                                  
                                                  
                                              </div>
                                          </div>
                                      </div>
                                    
                                      <div className="col-md-6 respSSL"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                              
                          </div>
                      </div>
                  </div>
            </div>
            <div className="modal-footer">
            <div id="getReponse" style={{color:"red"}}></div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" onClick={()=>props.editHeader("frmEditHeader")} className="btn _btnSetColor123 btn-primary">Save Changes</button>
            </div>
            </form>:''}
          </div>
          </div>
          </div>
      );
    
}
export default CMS_Edit_Header;
