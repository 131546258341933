import $, { extend } from 'jquery';
import React from 'react';
import select2 from 'select2';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,getWaUrlPath
,RespBkMthdMapp,_getInitParam} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import Papa from 'papaparse';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';
import Edit_Product_Master from '../components/edit_product_master';
import Edit_Product_Images from '../components/edit_product_images';
import Edit_Product_Categories from '../components/edit_product_categories';
import Manage_Vendor from '../components/manage_vendor';
import Manage_Custom_Parts from '../components/manage_custom_parts';
import Manage_Spare_Parts from '../components/manage_spare_parts';
import Manage_Related_Products from '../components/manage_related_products';
import Enable_Disable_Product from '../components/enable_disable_product';
import Manage_Product_Stock from '../components/manage_product_stock';


let loginUser="";
let userObject="";
let companyName="";
let clsUserMgmt;

let rf="0";
let _imgPath="";

let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];


let objVideo=[];
let vidIndexExisting=0;
let arrVidExistingFiles=[];

let objPdf=[];
let pdfIndexExisting=0;
let arrPdfExistingFiles=[];

let totalFileAllow=5;
let _recentTrigData={};

let _cmLibViwProdSet={};

//updateStockHoriHobby method used for ->
class View_Product_Master extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        _imgPath=getWaUrlPath();
        
        _cmLibViwProdSet=_getInitParam();
        //alert(JSON.stringify(_cmLibViwProdSet));
        if(typeof _cmLibViwProdSet!=="undefined"){
          _cmLibViwProdSet=_cmLibViwProdSet.app_settings.settings.view_products_action;
        }
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ listUsers:[],editProduct:{},creditBalance:[],masterBalance:[],
                    stListCategories:[],stListVendors:[],stProCategories:[],
                  stListAllProducts:[],stSpareProducts:[],stCustomProducts:[],stRelatedProducts:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      clsUserMgmt=this;
      }
      setPaginationCall(){
        //window.getPagination("#tbData");
      }
      resetFilters(){
        $("#ser_text").val("");
        $("#ser_proCategory").val("0");
        $("#ser_Vendor").val("0");
        $("#ser_pro_status").val("0");

        clsUserMgmt.getProducts();
      }
      exportToCSV()
      {
        // const { jsonData } = {
        //   jsonData: [
        //     { name: 'John', age: 30, city: 'New York' },
        //     { name: 'Alice', age: 25, city: 'San Francisco' },
        //     { name: 'Bob', age: 35, city: 'Seattle' },
        //   ],
        // };
        // alert(JSON.stringify(this.state.listUsers));
        // return;
        let customList=[];
        for(let i=0;i<this.state.listUsers.length;i++){
          customList.push({"product_code":this.state.listUsers[i]["product_code"],
        "product_name":this.state.listUsers[i]["product_name"],
      "cost":this.state.listUsers[i]["cost"]});
        }
        const { jsonData }={jsonData:customList}   

    // Convert JSON to CSV format using papaparse
    const csv = Papa.unparse(jsonData);

    // Create a Blob and create a downloadable link
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'web_item_master.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

      }

      getProducts(isSearch=false){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getProductMaster"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_proCategory",value:$("#ser_proCategory").val()});
        map.push({name:"ser_Vendor",value:$("#ser_Vendor").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"status",value:$("#ser_pro_status").val()})
       
       
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getProductMaster";


        if(!isSearch){
          map.push({name:"categories",value:"yes"});//for call with sep category
          map.push({name:"get_vendor",value:"yes"});//for call with sep vendor
          CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
          
            $(".select2").select2();
            $("#cmbReltProd").select2();
              if(data!=="0" && data!==""){
                  let listData=JSON.parse(data);
                  var strData="";

                  //alert(JSON.stringify(listData.products));
                  
                  clsUserMgmt.setState({listUsers:listData.products,
                    stListCategories:listData.categories,stListVendors:listData.vendor_list,
                  stListAllProducts:listData.products},()=>{
                      clsUserMgmt.setPaginationCall()
                    clsUserMgmt.forceUpdateHandler();
                    //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                    
                    if(rf!=="0"){
                      clsUserMgmt.getSearch(rf);
                      //alert("i m in setup "+rf);
                      $("#serLoanStatus").val(rf);
                    }
                  });
                }
          });
        }else{
          CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
            //alert(data);
              if(data!=="0" && data!==""){
                  let listData=JSON.parse(data);
                  var strData="";
                  clsUserMgmt.setState({listUsers:listData.products},()=>{
                      clsUserMgmt.setPaginationCall()
                    //clsUserMgmt.forceUpdateHandler();
                    //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                   
                    if(rf!=="0"){
                      clsUserMgmt.getSearch(rf);
                      //alert("i m in setup "+rf);
                      $("#serLoanStatus").val(rf);
                    }
                  });
                }
          });
        }
        
      }
      okSucc(data,smg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful","Record Added Successfully",true);
          clsUserMgmt.getUsers();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",smg,false);
        }
        // alert(data);
      }
 onProductImages(data){
  _recentTrigData=data;
  clsUserMgmt.setState({editProduct:data},()=>{
    RespBkMthdMapp(clsUserMgmt,"_setbackparam","onDelProCatRel");
    //clsUserMgmt.getCreditBalance(userCredit["id"]);
  })
 }

 
 /* Start Remove Pro Images */
 onDelProImage(param1,param2){
  
  let map=[];
  map.push({name:"curl_type",value:"removeProductImage"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"img_name",value:param1});
  map.push({name:"product_id",value:param2});
 
  map["res_func"]="callBackrrr";
  map["curl_type"]="removeProductImage";
  CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let cbkData=JSON.parse(data);
      //alert("Unmapped Successfully");
      clsUserMgmt.onProductImages(cbkData[0]);
    }
  });

 }
 /* End of Pro Images */


 okSuccCatMap(data,msg){
  if(data==="001"){
    $('#divProductCategories').modal('hide');
    $("#epc_category").val(0);
    alert("Category Mapped Successfully");
    //clsUserMgmt.onProductCategory(_recentTrigData);
    clsUserMgmt.getProducts();
  }
 }

 /* on Category Mapp Segment */
 onDelProCatRel(rfId){
  //alert(rfId);
  
  let map=[];
  map.push({name:"curl_type",value:"deleteRelProCate"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"pro_rel_cate",value:rfId});
 
  map["res_func"]="callBackrrr";
  map["curl_type"]="deleteRelProCate";
  CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
    if(data==="001"){
      alert("Unmapped Successfully");
      clsUserMgmt.onProductCategory(_recentTrigData);
    }
  })
}

 onProductCategory(data){
  let itemData=data;
  _recentTrigData=data;
  let map=[];
  map.push({name:"curl_type",value:"getProductCategories"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"product_id",value:data.rf_id});
 
  map["res_func"]="callBackrrr";
  map["curl_type"]="getProductCategories";
  CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let proCatList=JSON.parse(data);
      clsUserMgmt.setState({stProCategories:proCatList,editProduct:itemData},()=>{
        RespBkMthdMapp(clsUserMgmt,"_setbackparam","onDelProCatRel");
      });
    }
    
  });

 }
 /* end of category map segment */

 /* Start spare parts segment */
 onDelSpareRel(param1,param2){
  //alert(rfId);
  
  let map=[];
  map.push({name:"curl_type",value:"removeSpareProduct"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"spare_id",value:param1});
  map.push({name:"product_id",value:param2});
 
  map["res_func"]="callBackrrr";
  map["curl_type"]="removeSpareProduct";
  CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
    if(data==="001"){
      alert("Unmapped Successfully");
      window.location.reload();
      //clsUserMgmt.onSpareProMap(_recentTrigData);
    }
  });
 }
 okSuccSpare(data,msg){
  if(data=="001"){
    alert("Spare Parts Mapped Successfully");
    $("#cmbSpareCategory").select2('val', '');
    $("#cmbSpareCategory").val("");
    $("#cmbSpareCategory").trigger("change");

    $('#divManageSpareParts').modal('hide');
    window.location.reload();
    //clsUserMgmt.onSpareProMap(_recentTrigData);
  }
 }
onSpareProMap(data){
  _recentTrigData=data;
  
  let map=[];
  map.push({name:"curl_type",value:"getSpareProducts"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"product_id",value:data.rf_id});
 
  map["res_func"]="callBackrrr";
  map["curl_type"]="getSpareProducts";
  CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let spareProducts=JSON.parse(data);
      clsUserMgmt.setState({stSpareProducts:spareProducts,editProduct:_recentTrigData},()=>{
        RespBkMthdMapp(clsUserMgmt,"_setbackparam","onDelSpareRel");
      });
    }
    
  });
}
 /* END Spare Parts Map Segment */

 
 /* Start CUSTOM parts segment */
 onDelCustomRel(param1,param2){
  //alert(rfId);
  //alert("p1="+param1+",p2="+param2);
  let map=[];
  map.push({name:"curl_type",value:"removeSpareProduct"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"spare_id",value:param1});
  map.push({name:"product_id",value:param2});
  map.push({name:"is_custom",value:"true"});
 
  map["res_func"]="callBackrrr";
  map["curl_type"]="removeSpareProduct";
  CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
    if(data==="001"){
      alert("Unmapped Successfully");
      window.location.reload();
      //clsUserMgmt.onCustomProMap(_recentTrigData);
    }
  });
 }
 okSuccCustom(data,msg){
  if(data=="001"){
    $("#cmbCustomCategory").select2('val', '');
    $("#cmbCustomCategory").val("");
    $("#cmbCustomCategory").trigger("change");

    $('#divManageCustomParts').modal('hide');
    window.location.reload();
    //clsUserMgmt.onCustomProMap(_recentTrigData);
  }
 }
onCustomProMap(data){
  _recentTrigData=data;
  
  let map=[];
  map.push({name:"curl_type",value:"getSpareProducts"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"product_id",value:data.rf_id});
  map.push({name:"is_custom",value:"true"});
 
  map["res_func"]="callBackrrr";
  map["curl_type"]="getSpareProducts";
  CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let spareProducts=JSON.parse(data);
      clsUserMgmt.setState({stCustomProducts:spareProducts,editProduct:_recentTrigData},()=>{
        RespBkMthdMapp(clsUserMgmt,"_setbackparam","onDelCustomRel");
      });
    }
    
  });
}
 /* END CUSTOM Parts Map Segment */


 /* Start Related Products segment */
 onDelRelatedProduct(param1,param2){
  //alert(rfId);
  //alert("p1="+param1+",p2="+param2);
  let map=[];
  map.push({name:"curl_type",value:"removeSpareProduct"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"spare_id",value:param1});
  map.push({name:"product_id",value:param2});
  map.push({name:"is_related",value:"true"});
 
  map["res_func"]="callBackrrr";
  map["curl_type"]="removeSpareProduct";
  CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
    if(data==="001"){
      alert("Unmapped Successfully");
      window.location.reload();
      //clsUserMgmt.onCustomProMap(_recentTrigData);
    }
  });
 }
 okSuccRelatProd(data,msg){
  if(data=="001"){
    $("#cmbCustomCategory").select2('val', '');
    $("#cmbCustomCategory").val("");
    $("#cmbCustomCategory").trigger("change");

    $('#divManageRelatedProducts').modal('hide');
    window.location.reload();
    //clsUserMgmt.onCustomProMap(_recentTrigData);
  }
 }
onRelateProductMap(data){
  _recentTrigData=data;
  
  let map=[];
  map.push({name:"curl_type",value:"getSpareProducts"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"product_id",value:data.rf_id});
  map.push({name:"is_related",value:"true"});
 
  map["res_func"]="callBackrrr";
  map["curl_type"]="getSpareProducts";
  CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let relatedProducts=JSON.parse(data);
      clsUserMgmt.setState({stRelatedProducts:relatedProducts,editProduct:_recentTrigData},()=>{
        RespBkMthdMapp(clsUserMgmt,"_setbackparam","onDelCustomRel");
      });
    }
    
  });
}
 /* END Related Products Map Segment */

 onSetVendor(data){
   this.setState({editProduct:data});
 }

 okSuccVendor(data,msg){
  if(data==="001"){
    $('#divManageVendor').modal('hide');
    alert("Vendor Updated Successfully");

  }
 }

 okProductStatus(data,msg){
  if(data==="001"){
    $('#divEnableDisable').modal('hide');
    alert("Status Updated Successfully");
    window.location.reload();
  }
 }
 okStockUpdate(data,msg){
  if(data==="001"){
    $('#divManageProStock').modal('hide');
    alert(msg);
    window.location.reload();
  }
 }
     

 onCreditBalanceSel(data){
  //alert(JSON.stringify(data));
  /*
  // method will work on click on Credit Button Link to set State
  clsUserMgmt.setState({editProduct:editProduct},()=>{
    //clsUserMgmt.getCreditBalance(userCredit["id"]);
  })
  */
  $("#product_id").val(data["rf_id"]);
  $("#txt_name").val(data["product_name"]);
  $("#txt_code").val(data["product_code"]);
  $("#txt_cost").val(data["cost"]);
  $("#pre_cost").val(data["pre_cost"]);
  $("#offer_per").val(data["offer_per"]);
  $("#txt_weight").val(data["product_weight"]);
  $("#cmbUnit").val(data["product_unit"]);
  $("#p_length").val(data["p_length"]);
  $("#p_width").val(data["p_width"]);
  $("#p_height").val(data["p_height"]);
  $("#tax_slab").val(data["tax_slab"]);
  //$("textarea[name='description']").val(data["description"]);
  $("#txtProDes").summernote('code', data["product_description"]);
  $("#txtUsa").summernote('code', data["usage"]);
  $("#txtCom").summernote('code', data["composition"]);
  $("#txtFAQ").summernote('code', data["faq"]);
  /*
  let fmDate=FormatDate(data["enactment_date"]);
  //alert(fmDate);
  $("#enactment_date").val(fmDate);
  $("#extDate").html(fmDate);
  $("#ex_files").val(data["upload_path"].trim());
*/
 }
 
 //  check file validation
 fileValidation(){
  let allowFile=true;
let imgCount=objImages.length;
let vidCount=objVideo.length;
let pdfCount=objPdf.length;

let vTotalCount=imgCount+vidCount+pdfCount;

if(vTotalCount>=totalFileAllow){
alert("Only "+totalFileAllow+" to be allowed .");
allowFile=false;
}
return allowFile;
}

/* remove code logic */
rmFileProc(data){
  
  if(document.getElementById("ref_div_"+data)!=null){
    document.getElementById("ref_div_"+data).remove();
  }
  
  this.lcRemoveJSON(data,"image");
}
/* Loc */
lcRemoveJSON(refKey,fType){
if(fType==="image"){
var jsonP=JSON.parse($("#lsImages").val());
var arrJson=jsonP.objs;
for(var ai=0;ai<arrJson.length;ai++){
  if(arrJson[ai]["name"]===refKey){
    arrJson.splice(ai,1);
    $("#lsImages").val(JSON.stringify(jsonP));
    
    break;
  }
}
objImages=arrJson;

}else if(fType==="video"){
var jsonP=JSON.parse($("#lsVideo").val());
var arrJson=jsonP.objs;
for(var ai=0;ai<arrJson.length;ai++){
  if(arrJson[ai]["name"]===refKey){
    arrJson.splice(ai,1);
    $("#lsVideo").val(JSON.stringify(jsonP));
    
    break;
  }
}
objVideo=arrJson;
}else if(fType==="pdf"){
var jsonP=JSON.parse($("#lsPdf").val());
var arrJson=jsonP.objs;
for(var ai=0;ai<arrJson.length;ai++){
  if(arrJson[ai]["name"]===refKey){
    arrJson.splice(ai,1);
    $("#lsPdf").val(JSON.stringify(jsonP));
    
    break;
  }
}
objPdf=arrJson;
}



}

//image callback
ImageThumbBack(flImg, flName,fObject,othInfo="") {
  
  if(!this.fileValidation()){
    return;
  }

  if(flImg==="-10"){
    alert(flName);
    return;
  }
let strSet="";
 
    let nFlImg=flImg;
  if(othInfo===""){
    othInfo="nm"+indexExisting+","+indexExisting;
  }
  indexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  
    if(nFlImg.indexOf("data:video/webm")!==-1 || nFlImg.indexOf("data:video/mp4")!==-1){
     strSet+= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+nFlImg+"\"><video controls type=\"video/mp4\" src=\"" + nFlImg + "\"/></a>" +
        "</div>";
    }else{
       strSet += "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objImages.push({ name: arrNmValue[1], value: nFlImg });

  
 // alert(objImages.length);
  
  //alert(strSet);            
  
  $("#lsImages").val("{\"objs\":" + JSON.stringify(objImages) + "}");

  $(".imgLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }
//   $('.gallery-item').magnificPopup({
//     type: 'image',
//     gallery: {
//         enabled: true
//     }
// });
RespBkMthdMapp(clsUserMgmt, "_removeBtn"+indexExisting, "rmFileProc", "2");
  //imgLibThumb
      }


      isFloat(n) {
        let checkNum = false;
        if (Number(n)) {
            checkNum = true;
        } else if (n % 1 !== 0) {
            checkNum = true;
        } else {
            checkNum = false;
        }
      
        return checkNum;
      }
      
 
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getProducts();
$(".cleditor").summernote();

$('#divProductImages').on('hidden.bs.modal', function () {
  window.location.reload();
  // do something…
});

/* for edit product event */
$(".cd_input_change").change(function () {
  let objPreCost = document.getElementById("pre_cost");
  let objOfferPer = document.getElementById("offer_per");
  let objFCost = document.getElementById("txt_cost");

    let valPreCost = 0;
    let valOfferPer = 0;
    let valFCost = 0;

    if (clsUserMgmt.isFloat(objPreCost.value.trim())) {
      valPreCost = objPreCost.value.trim();
    }
    if (clsUserMgmt.isFloat(objOfferPer.value.trim())) {
      valOfferPer = objOfferPer.value.trim();
    }
    if (clsUserMgmt.isFloat(objFCost.value.trim())) {
      valFCost = objFCost.value.trim();
    }

    if(valOfferPer!==0){
      objFCost.value = valPreCost-(valPreCost * valOfferPer / 100).toFixed(2);
    }else{
      objFCost.value=objPreCost.value;
    }



})

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">
<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_proCategory" name="ser_proCategory">
           <option value="0">-Product Category-</option>
           {this.state.stListCategories.map((item,index)=>{
            return (<option value={item.id}>{item.category_name}</option>)
           })}
           {/* <option value="1">-Reseller-</option>
           <option value="3">-User-</option> */}
          
         </select>
</div>
<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_Vendor" name="ser_Vendor">
           <option value="0">-Vendor List-</option>
           {this.state.stListVendors.map((item,index)=>{
            return (<option value={item.id}>{item.first_name}</option>)
           })}
           {/* <option value="1">-Reseller-</option>
           <option value="3">-User-</option> */}
          
         </select>
</div>
<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_pro_status" name="ser_pro_status">
           <option value="0">-Product Status-</option>
           <option value="allow">-Enabled-</option>
           <option value="disabled">-Disabled-</option>
         </select>
</div>

<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" rf="#btnProSearch" className="_setSearchKeyUp keyEvent_btn form-control form-control-sm" placeholder='search' />
             </div>
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div id="btnProSearch" name="btnProSearch" onClick={()=>this.getProducts(true)}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>this.resetFilters()} className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Product List</b></h3>
                
                <a className="btn btn-sm btn-danger float-right" href="../add_product_master">Add New Product</a>
                <a onClick={()=>this.exportToCSV()} className="btn btn-sm btn-primary float-right" href="javascript:void(0)">Export to Csv</a>
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Image</th>
                      <th>Pro Code</th>
                      <th>Product Name</th>
                      <th>Rate</th>
                      <th>Weight</th> 
                      <th>Stock</th>
                      <th>Vendor</th>
                      <th>Category</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.listUsers.map((item,index)=>{
                    let strImgPath=item.img_path.split(',');
                       return(<tr>
                        <td>{index+1}</td>
                    <td><img height="50px" width="50px" src={_imgPath+strImgPath[0]}/></td>
                  <td>{item.product_code}<br></br>
                  {(item.spare_parts!=="" && item.spare_parts!==null) ?<span><a onClick={()=>this.onSpareProMap(item)} data-toggle="modal" data-target="#divManageSpareParts" href="javascript:void(0)">Spare Parts ({item.spare_parts.split(',').length})</a></span>:''}<br></br>
                  {(item.custom_parts!=="" && item.custom_parts!==null) ?<span><a onClick={()=>this.onCustomProMap(item)} data-toggle="modal" data-target="#divManageCustomParts" href="javascript:void(0)">Custom Parts ({item.custom_parts.split(',').length})</a></span>:''}
                  {(item.related_products!=="" && item.related_products!==null) ?<span><a onClick={()=>this.onRelateProductMap(item)} data-toggle="modal" data-target="#divManageRelatedProducts" href="javascript:void(0)">RelatedProducts ({item.related_products.split(',').length})</a></span>:''}
                  </td>
                  <td>{item.product_name}</td>
                  <td>{item.cost}</td>
                  <td>{item.product_weight}</td>
                  <td><span className="badge bg-primary">{item.max_limit_qty}</span></td>
                  <td>{item.vendor_company}</td>
                  <td><a data-toggle="modal" data-target="#divEmployee" href="javascript:void(0)">{item.categories}</a></td>
                  <td><span className="badge bg-danger dash_rejected_claim1">{item.status}</span></td>
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                   
                     {typeof _cmLibViwProdSet.map_category!=="undefined"?<a class="dropdown-item" onClick={()=>this.onProductCategory(item)}  data-toggle="modal" data-target="#divProductCategories" href="javascript:void(0)">Map Category</a>:''}
                     {typeof _cmLibViwProdSet.map_vendor!=="undefined"?<a class="dropdown-item" data-toggle="modal" onClick={()=>this.onSetVendor(item)} data-target="#divManageVendor" href="javascript:void(0)">Map Vendor</a>:''}
                     {typeof _cmLibViwProdSet.manage_stock!=="undefined"?<a class="dropdown-item" onClick={()=>this.onRelateProductMap(item)} data-toggle="modal" data-target="#divManageProStock" href="javascript:void(0)">Manage Stock</a>:''}

                     {typeof _cmLibViwProdSet.product_images!=="undefined"?<a class="dropdown-item" onClick={()=>this.onProductImages(item)} data-toggle="modal" data-target="#divProductImages" href="javascript:void(0)">Product Images</a>:''}
                      <div class="dropdown-divider"></div>
                      {typeof _cmLibViwProdSet.edit_product!=="undefined"?<a class="dropdown-item" onClick={()=>this.onCreditBalanceSel(item)} data-toggle="modal" data-target="#divEditProduct"  href="javascript:void(0)">Edit Product</a>:''}
                      <div class="dropdown-divider"></div>
                      {typeof _cmLibViwProdSet.map_spare_parts!=="undefined"?<a class="dropdown-item" onClick={()=>this.onSpareProMap(item)} data-toggle="modal" data-target="#divManageSpareParts" href="javascript:void(0)">Map Spare Parts</a>:''}
                      {typeof _cmLibViwProdSet.map_custom_parts!=="undefined"?<a class="dropdown-item" onClick={()=>this.onCustomProMap(item)} data-toggle="modal" data-target="#divManageCustomParts" href="javascript:void(0)">Map Custom Parts</a>:''}
                      {typeof _cmLibViwProdSet.related_products!=="undefined"?<a class="dropdown-item" onClick={()=>this.onRelateProductMap(item)} data-toggle="modal" data-target="#divManageRelatedProducts" href="javascript:void(0)">Related Products</a>:''}
                      <div class="dropdown-divider"></div>
                      {typeof _cmLibViwProdSet.enable_disable!=="undefined"?<a class="dropdown-item" onClick={()=>this.onRelateProductMap(item)} data-toggle="modal" data-target="#divEnableDisable" href="javascript:void(0)">Enable/Disable</a>:''}

                    </div>
                  </div>

                  </td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>

    <Edit_Product_Master creditUser={this.state.crediUser} editProduct={this.state.editProduct} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} />
    <Edit_Product_Images addNewCategory={()=>this.addNewProductCategory()} creditUser={this.state.crediUser} editProduct={this.state.editProduct} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} />
    <Edit_Product_Categories lsCategories={this.state.stListCategories} listexCategories={this.state.stProCategories} editProduct={this.state.editProduct} app_user_id={userObject[0].app_user_id} />
    <Manage_Related_Products lsRelatedProducts={this.state.stRelatedProducts} editProduct={this.state.editProduct} allProducts={this.state.stListAllProducts} app_user_id={userObject[0].app_user_id}/>
    <Manage_Spare_Parts lsSpareParts={this.state.stSpareProducts} editProduct={this.state.editProduct} allProducts={this.state.stListAllProducts} app_user_id={userObject[0].app_user_id} />
    <Manage_Custom_Parts lsCustomParts={this.state.stCustomProducts} editProduct={this.state.editProduct} allProducts={this.state.stListAllProducts} app_user_id={userObject[0].app_user_id} />
    

    <Manage_Vendor user_object={userObject} editProduct={this.state.editProduct} ListVendors={this.state.stListVendors}/>
    <Enable_Disable_Product user_object={userObject} editProduct={this.state.editProduct} />
    <Manage_Product_Stock user_object={userObject} editProduct={this.state.editProduct} />
          </div>)
      }
}
export default View_Product_Master;