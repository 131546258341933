import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,setEventsLib,
  RespBkMthdMapp} from '../function_lib/common_lib';
import {CallCMSMapMethod_CBack,getTreeMenu,RespBkMthdMappCMS,btnSetColorCMS_CBack} from '../function_lib/common_cms';
import Wa_Thrashold from '../components/wa_thrashold';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import {CMS_Add_New} from '../components/cms_add_new';
import {CMS_Edit_Data} from '../components/cms_edit_data';
import {CMS_Add_Header} from '../components/cms_add_header';
import {CMS_Edit_Header} from '../components/cms_edit_header';

import './cms_view.css';


let loginUser="";
let userObject="";
let companyName="";
let clsCMSView;

let _menuList=[];

let rf="0";


let totalFileAllow=8;

let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];


let objOthFiles=[];
let othIndexExisting=0;
let arrOthExistingFiles=[];

class CMS_View extends React.Component{

  //wor for componenetn addnewHeader
 addNewHeader(frmName){
  btnSetColorCMS_CBack(this,frmName,"cms_content_pg",function(data,msg){
   
     if(data==="001"){
      alert("Addedd Successfully");
     }else{
      alert("Unable to add , Kindly check all record and try again");
     }

    })
}
editHeader(frmName){
  btnSetColorCMS_CBack(this,frmName,"editCMS_Content_Pg",function(data,msg){
    if(data==="001"){
      alert("Addedd Successfully");
     }else{
      alert("Unable to add , Kindly check all record and try again");
     }
  })
}

    forceUpdateHandler(){
        this.forceUpdate();
      };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
            userObject= getUserObjData();
            //alert(JSON.stringify(userObject));
            loginUser=userObject[0].first_name;
            companyName=userObject[0].first_name;
        }
        clsCMSView=this;
        this.state={ stListHeaders:[],stHeadersData:[],stBNode:"",stPNode:"",stEditData:{},
      stMenuList:[],stHeaderEditData:[],stExMenuId:"0"};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      }
// file upload section
fileValidation(){
  let allowFile=true;
let imgCount=objImages.length;
let othFileCount=objOthFiles.length;


let vTotalCount=imgCount+othFileCount;

if(vTotalCount>=totalFileAllow){
alert("Only "+totalFileAllow+" to be allowed .");
allowFile=false;
}
return allowFile;
}

ImageOthThumbBack(flImg,flName,fObject,othInfo="",lsElement="",thumbnail=""){
  alert(thumbnail);
  if(!this.fileValidation()){
    return;
  }

  if(flImg==="-10"){
    alert(flName);
    return;
  }
  if(othInfo===""){
    othInfo="nm"+othIndexExisting+","+othIndexExisting;
  }
  othIndexExisting++;

  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"refoth_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+othIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!==-1 || flImg.indexOf("data:video/mp4")!==-1){
     strSet= "<div id=\"refoth_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+othIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objOthFiles.push({ name: arrNmValue[1], value: flImg });
 // alert(objImages.length);
 if(lsElement===""){
  $("#lsOthImages").val("{\"objs\":" + JSON.stringify(objOthFiles) + "}");
 }else{
  $("#"+lsElement).val("{\"objs\":" + JSON.stringify(objOthFiles) + "}");
 }
  
  if(thumbnail===""){
    $(".imgOthLibThumb").append(strSet);
  }else{
    $("."+thumbnail).append(strSet);
  }            
  
  if(fObject!==null){
    fObject.val('');
  }
//   $('.gallery-item').magnificPopup({
//     type: 'image',
//     gallery: {
//         enabled: true
//     }
// });
RespBkMthdMapp(clsCMSView, "_removeBtn"+othIndexExisting, "rmOthFileProc", "2");
  //imgLibThumb

}
rmOthFileProc(data){
  
  if(document.getElementById("refoth_div_"+data)!=null){
    document.getElementById("refoth_div_"+data).remove();
  }
  
  this.lcRemoveJSON(data,"oth_image");
}

rmFileProc(data){
  
  if(document.getElementById("ref_div_"+data)!=null){
    document.getElementById("ref_div_"+data).remove();
  }
  
  this.lcRemoveJSON(data,"image");
}
lcRemoveJSON(refKey,fType){
  if(fType==="image"){
    var jsonP=JSON.parse($("#lsImages").val());
    var arrJson=jsonP.objs;
    for(var ai=0;ai<arrJson.length;ai++){
      if(arrJson[ai]["name"]===refKey){
        arrJson.splice(ai,1);
        $("#lsImages").val(JSON.stringify(jsonP));
        
        break;
      }
    }
    objImages=arrJson;
    
  }else if(fType==="oth_image"){
    var jsonP=JSON.parse($("#lsOthImages").val());
    var arrJson=jsonP.objs;
    for(var ai=0;ai<arrJson.length;ai++){
      if(arrJson[ai]["name"]===refKey){
        arrJson.splice(ai,1);
        $("#lsOthImages").val(JSON.stringify(jsonP));
        
        break;
      }
    }
    objOthFiles=arrJson;
  }
  
  
   
  }
       

ImageThumbBack(flImg, flName,fObject,othInfo="",lsElement="",thumbnail="") {
  
  if(!this.fileValidation()){
    return;
  }

  if(flImg==="-10"){
    alert(flName);
    return;
  }

  if(othInfo===""){
    othInfo="nm"+indexExisting+","+indexExisting;
  }
  indexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!==-1 || flImg.indexOf("data:video/mp4")!==-1){
     strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objImages.push({ name: arrNmValue[1], value: flImg });
 // alert(objImages.length);
 if(lsElement===""){
  $("#lsImages").val("{\"objs\":" + JSON.stringify(objImages) + "}");
 }else{
  $("#"+lsElement).val("{\"objs\":" + JSON.stringify(objImages) + "}");
 }
  
  
  //alert(strSet);
if(thumbnail===""){
  $(".imgLibThumb").append(strSet);
}else{
  $("."+thumbnail).append(strSet);
}
  
  
  if(fObject!==null){
    fObject.val('');
  }
//   $('.gallery-item').magnificPopup({
//     type: 'image',
//     gallery: {
//         enabled: true
//     }
// });
RespBkMthdMapp(clsCMSView, "_removeBtn"+indexExisting, "rmFileProc", "2");
  //imgLibThumb
      }
// end of file upload section
     

      getTreeHeaders(status="0"){
        //child component use this method loan_document_upload_comp
        let map=[];
        map.push({name:"curl_type",value:"getTreeHeaders"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getTreeHeaders";
        CallCMSMapMethod_CBack(clsCMSView,map,function(data,msg){
          //alert(data);
          //$("#tUL").html(data);
          //return;
            if(data!=="0" && data!==""){
              let jsAllData=JSON.parse(data);
               let strTreeData=JSON.stringify(jsAllData.all_list);
               _menuList=jsAllData.all_list 
               let strTreeHeader=getTreeMenu(strTreeData);
                $("#tUL").html(strTreeHeader);
                RespBkMthdMappCMS(clsCMSView,"_setbackparam","getParamData");
                //third param not required but only used for your ref.
                clsCMSView.initTreeElement();
                
                clsCMSView.setState({stMenuList:jsAllData.menus});
                /*
                clsContactRequest.setState({stListHeaders:listData},()=>{
                    clsContactRequest.forceUpdateHandler();
                    setEventsLib("editable","confirm",function(e_id,e_key,text){
                        clsContactRequest.editThrasholdData(e_id,e_key,text);
                    })
                    
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsContactRequest.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
                */
               
              }
        });
      }
      
      okSucc(data,msg){
        
        if(data==="001"){
          alert(msg);
          clsCMSView.getThrasholdData();
        }
        
      }
      setExMenuId(param1,param2){
        //alert(param1);
        clsCMSView.setState({stExMenuId:param1});
      }
      getHeaderEditData(param1,param2){
       let jeditMenu=[];
       //alert(param1);
        for(let i=0;i<_menuList.length;i++){
          if(_menuList[i]["id"]===param1){
            jeditMenu.push(_menuList[i]);
            break;
          }
        }
        
       /*
       alert("i m testingtoo "+param1);
        for(let i=0;i<this.state.stMenuList.length;i++){
          if(this.state.stMenuList[i]["id"]===param1){
            jeditMenu.push(this.state.stMenuList[i]);
            break;
          }
        }
        */
       
        clsCMSView.setState({stHeaderEditData:jeditMenu});
      }
      
      getParamData(param1,param2){
        //alert(param1);
        let map=[];
        map.push({name:"curl_type",value:"getMappingforTreeNew"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"upper_id",value:userObject[0].upper_id});

        map.push({name:"b_node",value:param1});
        map.push({name:"p_node",value:param2});
        

        map["res_func"]="callBackrrr";
        map["curl_type"]="getMappingforTreeNew";
        CallCMSMapMethod_CBack(clsCMSView,map,function(data,msg){
          //alert(data);
          if(data!=="0" || data!==0){
            
            let listData=JSON.parse(data);
            clsCMSView.setState({stHeadersData:listData,stBNode:param1,stPNode:param2},()=>{
              clsCMSView.forceUpdateHandler();
            });
          }
        })
      }      
      
componentDidMount(){
   this.getTreeHeaders();
   $(".cleditor").summernote();
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);


$('#divEditHeader').on('hidden.bs.modal', function () {
  window.location.reload();
  // do something…
});

}
initTreeElement(){
  var toggler = document.getElementsByClassName("caret");
  var i;
  
  for (i = 0; i < toggler.length; i++) {
    toggler[i].addEventListener("click", function() {
      //alert(this.parentElement.querySelector(".nested"));
      if(this.parentElement.querySelector(".nested")!==null){
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      }
      
    });
  }
}
setEditContent(data){
this.setState({stEditData:data},()=>{
  //AjaxCallMethod(this,"okSuccCNOT");
  RespBkMthdMappCMS(clsCMSView,"_setbackparam","getParamData");
});
}

deleteContent(data){
  //alert(data.id);
  //return;
  let map=[];
  map.push({name:"curl_type",value:"deleteMappingNew"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"del_id",value:btoa(data.id)});

  map["res_func"]="callBackrrr";
  map["curl_type"]="deleteMappingNew";
  CallCMSMapMethod_CBack(clsCMSView,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      //let cbkData=JSON.parse(data);
      //alert("Unmapped Successfully");
     
    }
  });
}

// on delete existing images
onDelOthImage(param1,param2){
  let map=[];
  map.push({name:"curl_type",value:"removeImageContent"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"img_name",value:param1});
  map.push({name:"edit_id",value:param2});
  map.push({name:"img_of",value:"main"});
  map["res_func"]="callBackrrr";
  map["curl_type"]="removeImageContent";
  CallCMSMapMethod_CBack(clsCMSView,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      //let cbkData=JSON.parse(data);
      //alert("Unmapped Successfully");
     
    }
  });
}

onDelMainImage(param1,param2){
  
  let map=[];
  map.push({name:"curl_type",value:"removeImageContent"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"img_name",value:param1});
  map.push({name:"edit_id",value:param2});
  map.push({name:"img_of",value:"main"});
  map["res_func"]="callBackrrr";
  map["curl_type"]="removeImageContent";
  CallCMSMapMethod_CBack(clsCMSView,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      //let cbkData=JSON.parse(data);
      //alert("Unmapped Successfully");
     
    }
  });

 }

      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
    
<div className="row">
    <div className="col-lg-3">
            <div className="card">
            <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Headers</b></h3>
                
                <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divAddHeader" href="javascript:void(0)">Add New</a>
              </div>  
{/* start hier */}


<nav class="mt-2">
<ul id="tUL">
 </ul>
       </nav>   

      
            </div>
    </div>
      <div className="col-lg-8 col-md-8 col-sm-8 col-8">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
              <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b id="insideOff">Selected Data</b></h3>
                
                <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divCmsAddNew" href="javascript:void(0)">Add New</a>
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>Content Id.</th>
                      <th>Action</th>
                      {/* <th>Menu</th> */}
                      {/* <th>Content</th> */}
                      <th>Title</th>
                      <th>Short Description</th>
                      <th>Images/Files</th>
                      <th>Page Url</th>
                      <th>Video Link</th> 
                      {/* <th>Display Index</th> */}
                      
                    </tr>
                  </thead>
                  <tbody id="tbData">
                  {this.state.stHeadersData.map((item,index)=>{
                    if(index===0){
                      $("#insideOff").text(item.menu+"/"+item.content+"[Position-"+item.col18+"]");
                    }
                    return <tr>
                      <td>{item.content_id}</td>
                      <td>
                        <a href="javascript:void(0)" data-toggle="modal" data-target="#divCmsEditNew" onClick={()=>this.setEditContent(item)} ><i className="fa fa-pencil text-inverse"></i></a>
                        <a href="javascript:void(0)" onClick={()=>this.deleteContent(item)}><i className="fa fa-trash-o text-inverse text-danger ml-2 "></i></a>
                      </td>
                      {/* <td>{item.menu}</td> */}
                      {/* <td>{item.content}</td> */}
                      <td>{item.col1}</td>
                      <td>{item.col2}</td>
                      <td>{item.col4},{item.col5}</td>
                      <td>{item.col7}</td>
                      <td>{item.col6}</td>
                      {/* <td>{item.col18}</td> */}
                      
                    </tr>
                  })}
                  </tbody>
                </table>
           
              
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<Wa_Thrashold user_object={userObject}/>
<CMS_Add_New b_node={this.state.stBNode} p_node={this.state.stPNode}/>
<CMS_Edit_Data b_node={this.state.stBNode} p_node={this.state.stPNode} editData={this.state.stEditData} />
<CMS_Add_Header user_object={userObject} menus={this.state.stMenuList} exmenuid={this.state.stExMenuId} addNewHeader={this.addNewHeader}/>
<CMS_Edit_Header user_object={userObject} menus={this.state.stMenuList} editHeaderData={this.state.stHeaderEditData} editHeader={this.editHeader} /> 
          </div>)
      }
}
export default CMS_View;